@import 'src/theme/color';
@import 'src/theme/font';
@import 'src/theme/breakpoints';

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
}

.searchResults {
  border: 1px solid $color-search;
  background: $color-white;
  width: 500px;
  height: 419px;
  position: absolute;
  top: 134px;
  right: 15.5%;
  display: flex;
  align-items: stretch;
  justify-content: space-between;

  @include breakpoint(sm) {
    width: 100%;
    right: 0;
    top: 123px;
  }

  &.notFound {
    height: unset;
  }

  img {
    max-width: 113px;
  }

  .products {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    flex: 1;

    .list {
      padding: 0 5px 0 20px;
      overflow: auto;
      width: 100%;

      &::-webkit-scrollbar-track {
        border-radius: 2px;
        background-color: #e7d8d8;
      }

      &::-webkit-scrollbar {
        width: 4px;
        background-color: #f5f5f5;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #a69797;
      }

      .product-title {
        width: 370px;
      }

      .unit-selector-wrapper {
        display: none;
      }

      .product-actions {
        width: 300px;
        border-left: none;
      }

      .counter-wrapper {
        width: 150px;
      }

      .notFound {
        width: 100%;
        text-align: center;
        padding-left: 50px;
        padding-bottom: 15px;

        @include breakpoint(sm) {
          padding: 10px;
          text-align: left;
        }

        h3 {
          text-align: left;
          margin-top: 0;

          @include breakpoint(sm) {
            margin-bottom: 5px;
          }
        }
      }
    }

    .showAllButton {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 26px;
      cursor: pointer;
      color: $color-primary;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      padding: 10px 0;
      background-color: $color-product-bg;
      border: none;
    }
  }
}
